var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "event-type-modal-anchor",
            scrollable: "",
          },
          on: { hidden: _vm.cancelModal },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function ({ cancel }) {
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function ({}) {
                return [
                  !_vm.isAccessDenied
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.dataNotReady,
                            size: "sm",
                            variant: "success",
                          },
                          on: { click: _vm.handleOkBtnClicked },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: _vm.cancelModal },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: _vm.alertError ? "danger" : "success",
                dismissible: "",
                show: _vm.showError,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: {
                  icon: _vm.alertError
                    ? ["fas", "triangle-exclamation"]
                    : ["far", "check"],
                },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
              _c(
                "ul",
                { staticClass: "mb-0", attrs: { show: _vm.showErrorDetail } },
                [
                  _vm._l(_vm.alertMsgDetails, function (item, index) {
                    return [_c("li", { key: index }, [_vm._v(_vm._s(item))])]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-block" },
            [
              _c(
                "b-tabs",
                {
                  staticClass: "event-type",
                  attrs: { "content-class": "mt-3 tab-container", pills: "" },
                  model: {
                    value: _vm.typeTab,
                    callback: function ($$v) {
                      _vm.typeTab = $$v
                    },
                    expression: "typeTab",
                  },
                },
                [
                  _vm.isBookingVisible
                    ? _c(
                        "b-tab",
                        { attrs: { title: _vm.$t("staff.group.booking") } },
                        [
                          _vm.isBookingAccessDenied
                            ? _c(
                                "div",
                                { staticClass: "modal-message-overlay" },
                                [
                                  _c("span", { staticClass: "grid-overlay" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.bookingRestrictedRequiredField !=
                                            null
                                            ? _vm.$t(
                                                "entity_selector.error.insufficient_permission_to_add_entity_with_reason",
                                                [
                                                  _vm
                                                    .$t(
                                                      "booking.title_singular"
                                                    )
                                                    .toLowerCase(),
                                                  _vm.bookingRestrictedRequiredField,
                                                ]
                                              )
                                            : _vm.$t(
                                                "entity_selector.error.insufficient_permission_to_add_entity",
                                                [
                                                  _vm
                                                    .$t(
                                                      "booking.title_singular"
                                                    )
                                                    .toLowerCase(),
                                                ]
                                              )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              )
                            : _c(
                                "div",
                                { staticClass: "container pl-0" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "mr-1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        `booking.field.project`
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn-action",
                                                  attrs: {
                                                    id: "BOOKING_PROJECT_ADD",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.projectSelectorToggle,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["far", "plus"],
                                                    },
                                                  }),
                                                  _c("b-popover", {
                                                    attrs: {
                                                      target:
                                                        "BOOKING_PROJECT_ADD",
                                                      placement: "top",
                                                      triggers: "hover",
                                                      boundary: "viewport",
                                                      content: _vm.$t(
                                                        "booking.button.project_add"
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("BadgeGroup", {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        item,
                                                        index,
                                                      }) {
                                                        return [
                                                          _c("Badge", {
                                                            key: index,
                                                            attrs: {
                                                              text: `${item.name}`,
                                                              variant:
                                                                "primary",
                                                              pillable:
                                                                !!item.pillable,
                                                            },
                                                            on: {
                                                              badgeRemove:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.projectBookingBadgeRemove(
                                                                    index
                                                                  )
                                                                },
                                                              badgeClick:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.projectBookingBadgeClick(
                                                                    item.uuId
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2180183950
                                                ),
                                                model: {
                                                  value:
                                                    _vm.newEvent.projectBooking,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newEvent,
                                                      "projectBooking",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newEvent.projectBooking",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.customBookingFieldMap["projects"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customBookingFieldMap[
                                              "projects"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "projects" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.customBookingFieldMap["default"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customBookingFieldMap[
                                              "default"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.isDescriptionVisible
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "pr-0",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "booking.field.description"
                                                    ),
                                                    "label-for":
                                                      "booking-description",
                                                  },
                                                },
                                                [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      id: "booking-description",
                                                      placeholder: _vm.$t(
                                                        "booking.placeholder.description"
                                                      ),
                                                      "max-rows": 6,
                                                      trim: "",
                                                      rows: 3,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newEvent
                                                          .description,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newEvent,
                                                          "description",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newEvent.description",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.customBookingFieldMap[
                                        "description"
                                      ] != null
                                        ? _vm._l(
                                            _vm.customBookingFieldMap[
                                              "description"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "description" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0",
                                          attrs: { cols: "12", xl: "6" },
                                        },
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mb-3" },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "task.field.startTime"
                                                        ),
                                                        "label-for":
                                                          "task-startDate",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-datepicker", {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          "today-button": "",
                                                          "reset-button": "",
                                                          "close-button": "",
                                                          "hide-header": "",
                                                          "label-today-button":
                                                            _vm.$t(
                                                              "date.today"
                                                            ),
                                                          "label-reset-button":
                                                            _vm.$t(
                                                              "date.reset"
                                                            ),
                                                          "label-close-button":
                                                            _vm.$t(
                                                              "date.close"
                                                            ),
                                                          "today-button-variant":
                                                            "primary",
                                                          "reset-button-variant":
                                                            "danger",
                                                          "close-button-variant":
                                                            "secondary",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.dateChanged(
                                                              "startDate"
                                                            )
                                                          },
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "button-content",
                                                              fn: function ({}) {
                                                                return [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "calendar-days",
                                                                        ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2621928167
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .startDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent,
                                                              "startDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.startDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("Timepicker", {
                                                    attrs: { locale: "en" },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.dateChanged(
                                                          "startTime"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newEvent.startTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newEvent,
                                                          "startTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newEvent.startTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.customBookingFieldMap["startTime"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customBookingFieldMap[
                                              "startTime"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "startTime" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0",
                                          attrs: { cols: "12", xl: "6" },
                                        },
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mb-3" },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "task.field.closeTime"
                                                        ),
                                                        "label-for":
                                                          "task-closeDate",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-datepicker", {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          "today-button": "",
                                                          "reset-button": "",
                                                          "close-button": "",
                                                          "hide-header": "",
                                                          "label-today-button":
                                                            _vm.$t(
                                                              "date.today"
                                                            ),
                                                          "label-reset-button":
                                                            _vm.$t(
                                                              "date.reset"
                                                            ),
                                                          "label-close-button":
                                                            _vm.$t(
                                                              "date.close"
                                                            ),
                                                          "today-button-variant":
                                                            "primary",
                                                          "reset-button-variant":
                                                            "danger",
                                                          "close-button-variant":
                                                            "secondary",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.dateChanged(
                                                              "closeDate"
                                                            )
                                                          },
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "button-content",
                                                              fn: function ({}) {
                                                                return [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "calendar-days",
                                                                        ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2621928167
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .closeDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent,
                                                              "closeDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.closeDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("Timepicker", {
                                                    attrs: { locale: "en" },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.dateChanged(
                                                          "closeTime"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newEvent.closeTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newEvent,
                                                          "closeTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newEvent.closeTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.customBookingFieldMap["closeTime"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customBookingFieldMap[
                                              "closeTime"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "closeTime" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.isStageVisible
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "pr-0",
                                              attrs: { cols: "12", sm: "6" },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    id: "field-stage",
                                                    label:
                                                      _vm.$t(
                                                        "task.field.stage"
                                                      ),
                                                    "label-for": "task-stage",
                                                  },
                                                },
                                                [
                                                  _c("multiselect", {
                                                    staticClass:
                                                      "custom-dropdown-options enable-option-icon",
                                                    attrs: {
                                                      "max-height": 300,
                                                      options:
                                                        _vm.optionBookingStage.map(
                                                          (i) => i.key
                                                        ),
                                                      "custom-label":
                                                        _vm.getBookingStageOptionLabel,
                                                      placeholder: "",
                                                      searchable: false,
                                                      "allow-empty": true,
                                                      showLabels: false,
                                                      disabled:
                                                        _vm.isStageReadOnly,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "option",
                                                          fn: function (props) {
                                                            return [
                                                              _vm.bookingStage ==
                                                              props.option
                                                                ? _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      staticClass:
                                                                        "selected-option-icon",
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "check",
                                                                        ],
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "option__title",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getBookingStageOptionLabel(
                                                                        props.option
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      590234451
                                                    ),
                                                    model: {
                                                      value: _vm.bookingStage,
                                                      callback: function ($$v) {
                                                        _vm.bookingStage = $$v
                                                      },
                                                      expression:
                                                        "bookingStage",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.customBookingFieldMap["stage"] != null
                                        ? _vm._l(
                                            _vm.customBookingFieldMap[
                                              "stage"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "stage" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _vm.mode === "resource"
                                            ? _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "label",
                                                    { staticClass: "mr-1" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            `task.field.resources`
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.canList("RESOURCE")
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-action",
                                                          attrs: {
                                                            id: "BOOKING_RESOURCE_ADD",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.resourceSelectorToggle,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "plus",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                          _c("b-popover", {
                                                            attrs: {
                                                              target:
                                                                "BOOKING_RESOURCE_ADD",
                                                              placement: "top",
                                                              triggers: "hover",
                                                              content: _vm.$t(
                                                                "task.button.resource_add"
                                                              ),
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c("BadgeGroup", {
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            item,
                                                            index,
                                                          }) {
                                                            return [
                                                              _c("Badge", {
                                                                key: index,
                                                                attrs: {
                                                                  text: `${
                                                                    item.name
                                                                  } (${
                                                                    item.quantity >
                                                                    1
                                                                      ? item.quantity +
                                                                        " x "
                                                                      : ""
                                                                  }${
                                                                    item.utilization
                                                                      ? item.utilization *
                                                                        100
                                                                      : 100
                                                                  }%)`,
                                                                  variant:
                                                                    "primary",
                                                                  pillable:
                                                                    !!item.pillable,
                                                                },
                                                                on: {
                                                                  badgeRemove:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.resourceBadgeRemove(
                                                                        index
                                                                      )
                                                                    },
                                                                  badgeClick:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.resourceBadgeClick(
                                                                        item.uuId
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      136405116
                                                    ),
                                                    model: {
                                                      value: _vm.resources,
                                                      callback: function ($$v) {
                                                        _vm.resources = $$v
                                                      },
                                                      expression: "resources",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm.customBookingFieldMap["resources"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customBookingFieldMap[
                                              "resources"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "resources" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _vm.mode === "staff"
                                            ? _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "label",
                                                    { staticClass: "mr-1" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            `activity.field.staffs`
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.canList("STAFF")
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-action",
                                                          attrs: {
                                                            id: "BOOKING_STAFF_ADD",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.staffSelectorToggle,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "plus",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                          _c("b-popover", {
                                                            attrs: {
                                                              target:
                                                                "BOOKING_STAFF_ADD",
                                                              placement: "top",
                                                              triggers: "hover",
                                                              content: _vm.$t(
                                                                "activity.button.staff_add"
                                                              ),
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c("BadgeGroup", {
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            item,
                                                            index,
                                                          }) {
                                                            return [
                                                              _c("Badge", {
                                                                key: index,
                                                                attrs: {
                                                                  text: `${
                                                                    item.name
                                                                  } (${
                                                                    item.quantity >
                                                                    1
                                                                      ? item.quantity +
                                                                        " x "
                                                                      : ""
                                                                  }${
                                                                    item.utilization
                                                                      ? Math.trunc(
                                                                          item.utilization *
                                                                            100
                                                                        )
                                                                      : 100
                                                                  }%)`,
                                                                  variant:
                                                                    item.genericStaff
                                                                      ? "info"
                                                                      : "primary",
                                                                  pillable:
                                                                    !!item.pillable,
                                                                },
                                                                on: {
                                                                  badgeRemove:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.staffBadgeRemove(
                                                                        index
                                                                      )
                                                                    },
                                                                  badgeClick:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.staffBadgeClick(
                                                                        item.uuId
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      396406426
                                                    ),
                                                    model: {
                                                      value: _vm.staffs,
                                                      callback: function ($$v) {
                                                        _vm.staffs = $$v
                                                      },
                                                      expression: "staffs",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm.customBookingFieldMap["staffs"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customBookingFieldMap[
                                              "staffs"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "staffs" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                        ]
                      )
                    : _vm._e(),
                  _vm.isActivityVisible
                    ? _c(
                        "b-tab",
                        { attrs: { title: _vm.$t("staff.group.activity") } },
                        [
                          _vm.isActivityAccessDenied
                            ? _c(
                                "div",
                                { staticClass: "modal-message-overlay" },
                                [
                                  _c("span", { staticClass: "grid-overlay" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.activityRestrictedRequiredField !=
                                            null
                                            ? _vm.$t(
                                                "entity_selector.error.insufficient_permission_to_add_entity_with_reason",
                                                [
                                                  _vm
                                                    .$t(
                                                      "activity.title_singular"
                                                    )
                                                    .toLowerCase(),
                                                  _vm.activityRestrictedRequiredField,
                                                ]
                                              )
                                            : _vm.$t(
                                                "entity_selector.error.insufficient_permission_to_add_entity",
                                                [
                                                  _vm
                                                    .$t(
                                                      "activity.title_singular"
                                                    )
                                                    .toLowerCase(),
                                                ]
                                              )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              )
                            : _c(
                                "div",
                                { staticClass: "container pl-0" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  "activity.field.name"
                                                ),
                                                "label-for": "activity-name",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: { id: "activity-name" },
                                                model: {
                                                  value: _vm.activityName,
                                                  callback: function ($$v) {
                                                    _vm.activityName = $$v
                                                  },
                                                  expression: "activityName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.customActivityFieldMap["name"] != null
                                        ? _vm._l(
                                            _vm.customActivityFieldMap[
                                              "name"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "name" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.customActivityFieldMap["default"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customActivityFieldMap[
                                              "default"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.isDescriptionVisible
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "pr-0",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "activity.field.description"
                                                    ),
                                                    "label-for":
                                                      "activity-description",
                                                  },
                                                },
                                                [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      id: "activity-description",
                                                      placeholder: _vm.$t(
                                                        "activity.placeholder.description"
                                                      ),
                                                      "max-rows": 6,
                                                      trim: "",
                                                      rows: 3,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newEvent
                                                          .description,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newEvent,
                                                          "description",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newEvent.description",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.customActivityFieldMap[
                                        "description"
                                      ] != null
                                        ? _vm._l(
                                            _vm.customActivityFieldMap[
                                              "description"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "description" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0",
                                          attrs: { cols: "12", xl: "6" },
                                        },
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mb-3" },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "task.field.startTime"
                                                        ),
                                                        "label-for":
                                                          "task-startDate",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-datepicker", {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          "today-button": "",
                                                          "reset-button": "",
                                                          "close-button": "",
                                                          "hide-header": "",
                                                          "label-today-button":
                                                            _vm.$t(
                                                              "date.today"
                                                            ),
                                                          "label-reset-button":
                                                            _vm.$t(
                                                              "date.reset"
                                                            ),
                                                          "label-close-button":
                                                            _vm.$t(
                                                              "date.close"
                                                            ),
                                                          "today-button-variant":
                                                            "primary",
                                                          "reset-button-variant":
                                                            "danger",
                                                          "close-button-variant":
                                                            "secondary",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.dateChanged(
                                                              "startDate"
                                                            )
                                                          },
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "button-content",
                                                              fn: function ({}) {
                                                                return [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "calendar-days",
                                                                        ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2621928167
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .startDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent,
                                                              "startDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.startDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("Timepicker", {
                                                    attrs: { locale: "en" },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.dateChanged(
                                                          "startTime"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newEvent.startTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newEvent,
                                                          "startTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newEvent.startTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.customActivityFieldMap["startTime"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customActivityFieldMap[
                                              "startTime"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "startTime" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0",
                                          attrs: { cols: "12", xl: "6" },
                                        },
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mb-3" },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "task.field.closeTime"
                                                        ),
                                                        "label-for":
                                                          "task-closeDate",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-datepicker", {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          "today-button": "",
                                                          "reset-button": "",
                                                          "close-button": "",
                                                          "hide-header": "",
                                                          "label-today-button":
                                                            _vm.$t(
                                                              "date.today"
                                                            ),
                                                          "label-reset-button":
                                                            _vm.$t(
                                                              "date.reset"
                                                            ),
                                                          "label-close-button":
                                                            _vm.$t(
                                                              "date.close"
                                                            ),
                                                          "today-button-variant":
                                                            "primary",
                                                          "reset-button-variant":
                                                            "danger",
                                                          "close-button-variant":
                                                            "secondary",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.dateChanged(
                                                              "closeDate"
                                                            )
                                                          },
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "button-content",
                                                              fn: function ({}) {
                                                                return [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "calendar-days",
                                                                        ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2621928167
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .closeDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent,
                                                              "closeDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.closeDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("Timepicker", {
                                                    attrs: { locale: "en" },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.dateChanged(
                                                          "closeTime"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newEvent.closeTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newEvent,
                                                          "closeTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newEvent.closeTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.customActivityFieldMap["closeTime"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customActivityFieldMap[
                                              "closeTime"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "closeTime" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.isStageVisible
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "pr-0",
                                              attrs: { cols: "12", sm: "6" },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    id: "field-stage",
                                                    label:
                                                      _vm.$t(
                                                        "task.field.stage"
                                                      ),
                                                    "label-for": "task-stage",
                                                  },
                                                },
                                                [
                                                  _c("multiselect", {
                                                    staticClass:
                                                      "custom-dropdown-options enable-option-icon",
                                                    attrs: {
                                                      "max-height": 300,
                                                      options:
                                                        _vm.optionActivityStage.map(
                                                          (i) => i.key
                                                        ),
                                                      "custom-label":
                                                        _vm.getActivityStageOptionLabel,
                                                      placeholder: "",
                                                      searchable: false,
                                                      "allow-empty": true,
                                                      showLabels: false,
                                                      disabled:
                                                        _vm.isStageReadOnly,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "option",
                                                          fn: function (props) {
                                                            return [
                                                              _vm.activityStage ==
                                                              props.option
                                                                ? _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      staticClass:
                                                                        "selected-option-icon",
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "check",
                                                                        ],
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "option__title",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getActivityStageOptionLabel(
                                                                        props.option
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3159406387
                                                    ),
                                                    model: {
                                                      value: _vm.activityStage,
                                                      callback: function ($$v) {
                                                        _vm.activityStage = $$v
                                                      },
                                                      expression:
                                                        "activityStage",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.customActivityFieldMap["stage"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customActivityFieldMap[
                                              "stage"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "stage" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _vm.mode === "resource"
                                            ? _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "label",
                                                    { staticClass: "mr-1" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            `task.field.resources`
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass: "btn-action",
                                                      attrs: {
                                                        id: "ACTIVITY_RESOURCE_ADD",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.resourceSelectorToggle,
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: ["far", "plus"],
                                                        },
                                                      }),
                                                      _c("b-popover", {
                                                        attrs: {
                                                          target:
                                                            "ACTIVITY_RESOURCE_ADD",
                                                          placement: "top",
                                                          triggers: "hover",
                                                          content: _vm.$t(
                                                            "task.button.resource_add"
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("BadgeGroup", {
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            item,
                                                            index,
                                                          }) {
                                                            return [
                                                              _c("Badge", {
                                                                key: index,
                                                                attrs: {
                                                                  text: `${
                                                                    item.name
                                                                  } (${
                                                                    item.quantity >
                                                                    1
                                                                      ? item.quantity +
                                                                        " x "
                                                                      : ""
                                                                  }${
                                                                    item.utilization
                                                                      ? item.utilization *
                                                                        100
                                                                      : 100
                                                                  }%)`,
                                                                  variant:
                                                                    "primary",
                                                                  pillable:
                                                                    !!item.pillable,
                                                                },
                                                                on: {
                                                                  badgeRemove:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.resourceBadgeRemove(
                                                                        index
                                                                      )
                                                                    },
                                                                  badgeClick:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.resourceBadgeClick(
                                                                        item.uuId
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      136405116
                                                    ),
                                                    model: {
                                                      value: _vm.resources,
                                                      callback: function ($$v) {
                                                        _vm.resources = $$v
                                                      },
                                                      expression: "resources",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm.customActivityFieldMap["resources"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customActivityFieldMap[
                                              "resources"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "resources" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _vm.mode === "staff"
                                            ? _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "label",
                                                    { staticClass: "mr-1" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            `activity.field.staffs`
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass: "btn-action",
                                                      attrs: {
                                                        id: "ACTIVITY_STAFF_ADD",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.staffSelectorToggle,
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: ["far", "plus"],
                                                        },
                                                      }),
                                                      _c("b-popover", {
                                                        attrs: {
                                                          target:
                                                            "ACTIVITY_STAFF_ADD",
                                                          placement: "top",
                                                          triggers: "hover",
                                                          content: _vm.$t(
                                                            "activity.button.staff_add"
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("BadgeGroup", {
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            item,
                                                            index,
                                                          }) {
                                                            return [
                                                              _c("Badge", {
                                                                key: index,
                                                                attrs: {
                                                                  text: `${
                                                                    item.name
                                                                  } (${
                                                                    item.quantity >
                                                                    1
                                                                      ? item.quantity +
                                                                        " x "
                                                                      : ""
                                                                  }${
                                                                    item.utilization
                                                                      ? Math.trunc(
                                                                          item.utilization *
                                                                            100
                                                                        )
                                                                      : 100
                                                                  }%)`,
                                                                  variant:
                                                                    item.genericStaff
                                                                      ? "info"
                                                                      : "primary",
                                                                  pillable:
                                                                    !!item.pillable,
                                                                },
                                                                on: {
                                                                  badgeRemove:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.staffBadgeRemove(
                                                                        index
                                                                      )
                                                                    },
                                                                  badgeClick:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.staffBadgeClick(
                                                                        item.uuId
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      396406426
                                                    ),
                                                    model: {
                                                      value: _vm.staffs,
                                                      callback: function ($$v) {
                                                        _vm.staffs = $$v
                                                      },
                                                      expression: "staffs",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm.customActivityFieldMap["staffs"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customActivityFieldMap[
                                              "staffs"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "staffs" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                        ]
                      )
                    : _vm._e(),
                  _vm.isTaskVisible
                    ? _c(
                        "b-tab",
                        { attrs: { title: _vm.$t("staff.group.task") } },
                        [
                          _vm.isTaskAccessDenied
                            ? _c(
                                "div",
                                { staticClass: "modal-message-overlay" },
                                [
                                  _c("span", { staticClass: "grid-overlay" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.taskRestrictedRequiredField !=
                                            null
                                            ? _vm.$t(
                                                "entity_selector.error.insufficient_permission_to_add_entity_with_reason",
                                                [
                                                  _vm
                                                    .$t("task.title_singular")
                                                    .toLowerCase(),
                                                  _vm.taskRestrictedRequiredField,
                                                ]
                                              )
                                            : _vm.$t(
                                                "entity_selector.error.insufficient_permission_to_add_entity",
                                                [
                                                  _vm
                                                    .$t("task.title_singular")
                                                    .toLowerCase(),
                                                ]
                                              )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              )
                            : _c(
                                "div",
                                { staticClass: "container pl-0" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  _vm.$t("task.field.name"),
                                                "label-for": "task-name",
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: { id: "task-name" },
                                                model: {
                                                  value: _vm.taskName,
                                                  callback: function ($$v) {
                                                    _vm.taskName = $$v
                                                  },
                                                  expression: "taskName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.customTaskFieldMap["name"] != null
                                        ? _vm._l(
                                            _vm.customTaskFieldMap[
                                              "name"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "name" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.customTaskFieldMap["default"] != null
                                        ? _vm._l(
                                            _vm.customTaskFieldMap[
                                              "default"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "mr-1" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        `task.field.project`
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  staticClass: "btn-action",
                                                  attrs: {
                                                    id: "TASK_PROJECT_ADD",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.projectSelectorToggle,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["far", "plus"],
                                                    },
                                                  }),
                                                  _c("b-popover", {
                                                    attrs: {
                                                      target:
                                                        "TASK_PROJECT_ADD",
                                                      placement: "top",
                                                      triggers: "hover",
                                                      boundary: "viewport",
                                                      content: _vm.$t(
                                                        "task.button.project_add"
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("BadgeGroup", {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        item,
                                                        index,
                                                      }) {
                                                        return [
                                                          _c("Badge", {
                                                            key: index,
                                                            attrs: {
                                                              text: `${item.name}`,
                                                              variant:
                                                                "primary",
                                                              pillable:
                                                                !!item.pillable,
                                                            },
                                                            on: {
                                                              badgeRemove:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.projectTaskBadgeRemove(
                                                                    index
                                                                  )
                                                                },
                                                              badgeClick:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.projectTaskBadgeClick(
                                                                    item.uuId
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3750057614
                                                ),
                                                model: {
                                                  value:
                                                    _vm.newEvent.projectTask,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.newEvent,
                                                      "projectTask",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "newEvent.projectTask",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.customTaskFieldMap["projects"] != null
                                        ? _vm._l(
                                            _vm.customTaskFieldMap[
                                              "projects"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "projects" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.isDescriptionVisible
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "pr-0",
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "task.field.description"
                                                    ),
                                                    "label-for":
                                                      "task-description",
                                                  },
                                                },
                                                [
                                                  _c("b-form-textarea", {
                                                    attrs: {
                                                      id: "task-description",
                                                      placeholder: _vm.$t(
                                                        "task.placeholder.description"
                                                      ),
                                                      "max-rows": 6,
                                                      trim: "",
                                                      rows: 3,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newEvent
                                                          .description,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newEvent,
                                                          "description",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newEvent.description",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.customTaskFieldMap["description"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customTaskFieldMap[
                                              "description"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "description" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0",
                                          attrs: { cols: "12", xl: "6" },
                                        },
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "task.field.startTime"
                                                        ),
                                                        "label-for":
                                                          "task-startDate",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-datepicker", {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          "today-button": "",
                                                          "reset-button": "",
                                                          "close-button": "",
                                                          "hide-header": "",
                                                          "label-today-button":
                                                            _vm.$t(
                                                              "date.today"
                                                            ),
                                                          "label-reset-button":
                                                            _vm.$t(
                                                              "date.reset"
                                                            ),
                                                          "label-close-button":
                                                            _vm.$t(
                                                              "date.close"
                                                            ),
                                                          "today-button-variant":
                                                            "primary",
                                                          "reset-button-variant":
                                                            "danger",
                                                          "close-button-variant":
                                                            "secondary",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.dateChanged(
                                                              "startDate"
                                                            )
                                                          },
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "button-content",
                                                              fn: function ({}) {
                                                                return [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "calendar-days",
                                                                        ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2621928167
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .startDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent,
                                                              "startDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.startDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("Timepicker", {
                                                    attrs: { locale: "en" },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.dateChanged(
                                                          "startTime"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newEvent.startTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newEvent,
                                                          "startTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newEvent.startTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.customTaskFieldMap["startTime"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customTaskFieldMap[
                                              "startTime"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "startTime" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0",
                                          attrs: { cols: "12", xl: "6" },
                                        },
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mb-3" },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "task.field.closeTime"
                                                        ),
                                                        "label-for":
                                                          "task-closeDate",
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-datepicker", {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          "today-button": "",
                                                          "reset-button": "",
                                                          "close-button": "",
                                                          "hide-header": "",
                                                          "label-today-button":
                                                            _vm.$t(
                                                              "date.today"
                                                            ),
                                                          "label-reset-button":
                                                            _vm.$t(
                                                              "date.reset"
                                                            ),
                                                          "label-close-button":
                                                            _vm.$t(
                                                              "date.close"
                                                            ),
                                                          "today-button-variant":
                                                            "primary",
                                                          "reset-button-variant":
                                                            "danger",
                                                          "close-button-variant":
                                                            "secondary",
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return _vm.dateChanged(
                                                              "closeDate"
                                                            )
                                                          },
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "button-content",
                                                              fn: function ({}) {
                                                                return [
                                                                  _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "calendar-days",
                                                                        ],
                                                                      },
                                                                    }
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2621928167
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .closeDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent,
                                                              "closeDate",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.closeDate",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("Timepicker", {
                                                    attrs: { locale: "en" },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.dateChanged(
                                                          "closeTime"
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.newEvent.closeTime,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newEvent,
                                                          "closeTime",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newEvent.closeTime",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.customTaskFieldMap["closeTime"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customTaskFieldMap[
                                              "closeTime"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "closeTime" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.isStageVisible
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "pr-0",
                                              attrs: { cols: "12", sm: "6" },
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    id: "field-stage",
                                                    label:
                                                      _vm.$t(
                                                        "task.field.stage"
                                                      ),
                                                    "label-for": "task-stage",
                                                  },
                                                },
                                                [
                                                  _c("multiselect", {
                                                    staticClass:
                                                      "custom-dropdown-options enable-option-icon",
                                                    attrs: {
                                                      "max-height": 300,
                                                      options:
                                                        _vm.optionStage.map(
                                                          (i) => i.key
                                                        ),
                                                      "custom-label":
                                                        _vm.getTaskStageOptionLabel,
                                                      placeholder: "",
                                                      searchable: false,
                                                      "allow-empty": true,
                                                      showLabels: false,
                                                      disabled:
                                                        _vm.isStageReadOnly,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "option",
                                                          fn: function (props) {
                                                            return [
                                                              _vm.taskStage ==
                                                              props.option
                                                                ? _c(
                                                                    "font-awesome-icon",
                                                                    {
                                                                      staticClass:
                                                                        "selected-option-icon",
                                                                      attrs: {
                                                                        icon: [
                                                                          "far",
                                                                          "check",
                                                                        ],
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "option__title",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.getTaskStageOptionLabel(
                                                                        props.option
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1497273523
                                                    ),
                                                    model: {
                                                      value: _vm.taskStage,
                                                      callback: function ($$v) {
                                                        _vm.taskStage = $$v
                                                      },
                                                      expression: "taskStage",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.customTaskFieldMap["stage"] != null
                                        ? _vm._l(
                                            _vm.customTaskFieldMap[
                                              "stage"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "stage" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _vm.mode === "resource"
                                            ? _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "label",
                                                    { staticClass: "mr-1" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            `task.field.resources`
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.canView("RESOURCE")
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-action",
                                                          attrs: {
                                                            id: "TASK_RESOURCE_ADD",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.resourceSelectorToggle,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "font-awesome-icon",
                                                            {
                                                              attrs: {
                                                                icon: [
                                                                  "far",
                                                                  "plus",
                                                                ],
                                                              },
                                                            }
                                                          ),
                                                          _c("b-popover", {
                                                            attrs: {
                                                              target:
                                                                "TASK_RESOURCE_ADD",
                                                              placement: "top",
                                                              triggers: "hover",
                                                              content: _vm.$t(
                                                                "task.button.resource_add"
                                                              ),
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c("BadgeGroup", {
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            item,
                                                            index,
                                                          }) {
                                                            return [
                                                              _c("Badge", {
                                                                key: index,
                                                                attrs: {
                                                                  text: `${
                                                                    item.name
                                                                  } (${
                                                                    item.quantity >
                                                                    1
                                                                      ? item.quantity +
                                                                        " x "
                                                                      : ""
                                                                  }${
                                                                    item.utilization
                                                                      ? item.utilization *
                                                                        100
                                                                      : 100
                                                                  }%)`,
                                                                  variant:
                                                                    "primary",
                                                                  pillable:
                                                                    !!item.pillable,
                                                                },
                                                                on: {
                                                                  badgeRemove:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.resourceBadgeRemove(
                                                                        index
                                                                      )
                                                                    },
                                                                  badgeClick:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.resourceBadgeClick(
                                                                        item.uuId
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      136405116
                                                    ),
                                                    model: {
                                                      value: _vm.resources,
                                                      callback: function ($$v) {
                                                        _vm.resources = $$v
                                                      },
                                                      expression: "resources",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _vm.mode === "staff"
                                            ? _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "label",
                                                    { staticClass: "mr-1" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            `activity.field.staffs`
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass: "btn-action",
                                                      attrs: {
                                                        id: "TASK_STAFF_ADD",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.staffSelectorToggle,
                                                      },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: ["far", "plus"],
                                                        },
                                                      }),
                                                      _c("b-popover", {
                                                        attrs: {
                                                          target:
                                                            "TASK_STAFF_ADD",
                                                          placement: "top",
                                                          triggers: "hover",
                                                          content: _vm.$t(
                                                            "activity.button.staff_add"
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c("BadgeGroup", {
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function ({
                                                            item,
                                                            index,
                                                          }) {
                                                            return [
                                                              _c("Badge", {
                                                                key: index,
                                                                attrs: {
                                                                  text: `${
                                                                    item.name
                                                                  } (${
                                                                    item.quantity >
                                                                    1
                                                                      ? item.quantity +
                                                                        " x "
                                                                      : ""
                                                                  }${
                                                                    item.utilization
                                                                      ? Math.trunc(
                                                                          item.utilization *
                                                                            100
                                                                        )
                                                                      : 100
                                                                  }%)`,
                                                                  variant:
                                                                    item.genericStaff
                                                                      ? "info"
                                                                      : "primary",
                                                                  pillable:
                                                                    !!item.pillable,
                                                                },
                                                                on: {
                                                                  badgeRemove:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.staffBadgeRemove(
                                                                        index
                                                                      )
                                                                    },
                                                                  badgeClick:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.staffBadgeClick(
                                                                        item.uuId
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      396406426
                                                    ),
                                                    model: {
                                                      value: _vm.staffs,
                                                      callback: function ($$v) {
                                                        _vm.staffs = $$v
                                                      },
                                                      expression: "staffs",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm.customTaskFieldMap["resources"] !=
                                      null
                                        ? _vm._l(
                                            _vm.customTaskFieldMap[
                                              "resources"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "resources" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.customTaskFieldMap["staffs"] != null
                                        ? _vm._l(
                                            _vm.customTaskFieldMap[
                                              "staffs"
                                            ].filter((i) => i.notNull == true),
                                            function (field, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: "staffs" + index,
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    [
                                                      field.type !== "Boolean"
                                                        ? _c(
                                                            "template",
                                                            { slot: "label" },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      field.displayName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              field.description
                                                                ? _c(
                                                                    "span",
                                                                    [
                                                                      _c(
                                                                        "font-awesome-icon",
                                                                        {
                                                                          style:
                                                                            {
                                                                              color:
                                                                                "var(--form-control-placeholder)",
                                                                              fontSize:
                                                                                "0.9em",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              id: `${_vm.componentId}_${field.name}`,
                                                                              icon: [
                                                                                "far",
                                                                                "circle-question",
                                                                              ],
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "b-popover",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              target: `${_vm.componentId}_${field.name}`,
                                                                              triggers:
                                                                                "hover",
                                                                              placement:
                                                                                "top",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                field.description
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("CustomField", {
                                                        attrs: {
                                                          componentId:
                                                            _vm.componentId,
                                                          field: field,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newEvent
                                                              .customFields[
                                                              field.name
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newEvent
                                                                .customFields,
                                                              field.name,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newEvent.customFields[field.name]",
                                                        },
                                                      }),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                        ]
                      )
                    : _vm._e(),
                  _vm.isVacationVisible
                    ? _c(
                        "b-tab",
                        { attrs: { title: _vm.$t("staff.group.vacation") } },
                        [
                          _c(
                            "div",
                            { staticClass: "container pl-0" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "vacation.field.name"
                                            ),
                                            "label-for": "vacation-name",
                                          },
                                        },
                                        [
                                          _c("b-form-input", {
                                            attrs: { id: "vacation-name" },
                                            model: {
                                              value: _vm.vacationName,
                                              callback: function ($$v) {
                                                _vm.vacationName = $$v
                                              },
                                              expression: "vacationName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12", xl: "6" },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "task.field.startTime"
                                                    ),
                                                    "label-for":
                                                      "task-startDate",
                                                  },
                                                },
                                                [
                                                  _c("b-form-datepicker", {
                                                    staticClass: "mb-2",
                                                    attrs: {
                                                      "today-button": "",
                                                      "reset-button": "",
                                                      "close-button": "",
                                                      "hide-header": "",
                                                      "label-today-button":
                                                        _vm.$t("date.today"),
                                                      "label-reset-button":
                                                        _vm.$t("date.reset"),
                                                      "label-close-button":
                                                        _vm.$t("date.close"),
                                                      "today-button-variant":
                                                        "primary",
                                                      "reset-button-variant":
                                                        "danger",
                                                      "close-button-variant":
                                                        "secondary",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.dateChanged(
                                                          "startDate"
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "button-content",
                                                          fn: function ({}) {
                                                            return [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: [
                                                                      "far",
                                                                      "calendar-days",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2621928167
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.newEvent.startDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newEvent,
                                                          "startDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newEvent.startDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12", xl: "6" },
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "task.field.closeTime"
                                                    ),
                                                    "label-for":
                                                      "task-closeDate",
                                                  },
                                                },
                                                [
                                                  _c("b-form-datepicker", {
                                                    staticClass: "mb-2",
                                                    attrs: {
                                                      "today-button": "",
                                                      "reset-button": "",
                                                      "close-button": "",
                                                      "hide-header": "",
                                                      "label-today-button":
                                                        _vm.$t("date.today"),
                                                      "label-reset-button":
                                                        _vm.$t("date.reset"),
                                                      "label-close-button":
                                                        _vm.$t("date.close"),
                                                      "today-button-variant":
                                                        "primary",
                                                      "reset-button-variant":
                                                        "danger",
                                                      "close-button-variant":
                                                        "secondary",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.dateChanged(
                                                          "closeDate"
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "button-content",
                                                          fn: function ({}) {
                                                            return [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: [
                                                                      "far",
                                                                      "calendar-days",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2621928167
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.newEvent.closeDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.newEvent,
                                                          "closeDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "newEvent.closeDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c("label", { staticClass: "mr-1" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(`activity.field.staffs`)
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn-action",
                                              attrs: {
                                                id: "VACATION_STAFF_ADD",
                                              },
                                              on: {
                                                click: _vm.staffSelectorToggle,
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "plus"],
                                                },
                                              }),
                                              _c("b-popover", {
                                                attrs: {
                                                  target: "VACATION_STAFF_ADD",
                                                  placement: "top",
                                                  triggers: "hover",
                                                  content: _vm.$t(
                                                    "activity.button.staff_add"
                                                  ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("BadgeGroup", {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    item,
                                                    index,
                                                  }) {
                                                    return [
                                                      _c("Badge", {
                                                        key: index,
                                                        attrs: {
                                                          text: `${item.name}`,
                                                          variant:
                                                            item.genericStaff
                                                              ? "info"
                                                              : "primary",
                                                          pillable:
                                                            !!item.pillable,
                                                        },
                                                        on: {
                                                          badgeRemove:
                                                            function ($event) {
                                                              return _vm.staffBadgeRemove(
                                                                index
                                                              )
                                                            },
                                                          badgeClick: function (
                                                            $event
                                                          ) {
                                                            return _vm.vacationStaffBadgeClick(
                                                              item.uuId
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3454674976
                                            ),
                                            model: {
                                              value: _vm.staffs,
                                              callback: function ($$v) {
                                                _vm.staffs = $$v
                                              },
                                              expression: "staffs",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.state.staffSelectorShow
        ? _c("StaffSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.staffSelectorShow,
              projectIds: _vm.staffProjectIds,
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "staffSelectorShow", $event)
              },
              ok: _vm.staffSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.resourceSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.resourceSelectorShow,
              entityService: _vm.resourceUtil,
              projectIds: _vm.staffProjectIds,
              entity: "RESOURCE",
              nonAdmin: "",
              singleSelection: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "resourceSelectorShow", $event)
              },
              ok: _vm.resourceSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.projectSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.projectSelectorShow,
              entityService: _vm.projectUtil,
              entity: "PROJECT",
              nonAdmin: "",
              singleSelection: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "projectSelectorShow", $event)
              },
              ok: _vm.projectSelectorOk,
            },
          })
        : _vm._e(),
      _vm.state.staffUtilizationEditShow
        ? _c("StaffUtilizationModal", {
            attrs: {
              supportsUnit: _vm.typeTab !== _vm.bookingIndex,
              show: _vm.state.staffUtilizationEditShow,
              uuId: _vm.staffUtilizationEdit.uuId,
              name: _vm.staffUtilizationEdit.name,
              utilization: _vm.staffUtilizationEdit.utilization,
              unit: _vm.staffUtilizationEdit.unit,
              genericStaff: _vm.staffUtilizationEdit.genericStaff,
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "staffUtilizationEditShow", $event)
              },
              ok: _vm.staffUtilizationOk,
            },
          })
        : _vm._e(),
      _vm.state.resourceUnitEditShow
        ? _c("ResourceUnitModal", {
            attrs: {
              show: _vm.state.resourceUnitEditShow,
              uuId: _vm.resourceUnitEdit.uuId,
              name: _vm.resourceUnitEdit.name,
              unit: _vm.resourceUnitEdit.unit,
              utilization: parseFloat(_vm.resourceUnitEdit.utilization),
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "resourceUnitEditShow", $event)
              },
              ok: _vm.resourceUnitOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }