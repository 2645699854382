<template>
  <div>
    <div class="filter-group" :class="getClass(level)">
      <div class="filter-header display-block">
        <label class="mr-1">{{ $t('dataview.field.match_type') }}</label>
        <b-form-select :disabled="disabled" class="w-auto" v-model="query.operator" :options="groupOptions"></b-form-select>
        <b-alert size="sm" class="filter-header-alert" :show="query.operator === '_not_' && query.children.length > 1" variant="danger">
          <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ $t('dataview.error.not') }}
        </b-alert>
        <button v-if="level > 0" class="btn-remove" @click="removeGroup()"><font-awesome-icon class="btn-icon-color" :icon="['far', 'xmark']"/></button>
      </div>
      
      <div class="filter-buttons">
        <b-button :disabled="disabled || query.operator === '_not_' && query.children.length >= 1" class="mr-2" size="sm" @click="addRule(query.children)">{{ $t('button.add_rule') }}</b-button>
        <b-button :disabled="disabled || query.operator === '_not_' && query.children.length >= 1" size="sm" @click="addGroup(query.children)">{{ $t('button.add_group') }}</b-button>
      </div>
      
      <div v-bind:key="itemIndex" v-for="(item, itemIndex) in query.children" class="mt-2">
        <div v-if="item.type === 'rule'" class="filter-rule" :class="getRuleClass(level)">
           <div class="item-field-container">
            <div class="d-inline-block clickable"
              @click.stop="!disabled && editField(item)">
              <div
                class="item-field-label d-inline-block">
                {{ item.field !== null && item.field !== '' ? formatField(item) : $t('dataview.placeholder.field') }}
              </div>
              <div v-if="!disabled" :id="`EDIT_TYPE_${id}_${itemIndex}`" class="d-inline-block ml-2 mr-2 edit-icon-container">
                <font-awesome-icon class="edit-icon-color" :icon="['far', 'pen-to-square']"/>
                <b-popover
                  :target="`EDIT_TYPE_${id}_${itemIndex}`"
                  placement="top"
                  triggers="hover"
                  :content="$t('button.edit')">
                </b-popover>
              </div>
            </div>
          </div>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': item.fieldError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ $t('dataview.error.field') }}
          </b-form-invalid-feedback>
           
          <button v-if="!disabled" class="btn-rule-remove" @click="removeItem(itemIndex)"><font-awesome-icon class="btn-icon-color" :icon="['far', 'xmark']"/></button>
          <div class="d-flex">
            <b-form-group class="w-40">
              <b-form-select :disabled="disabled" class="mr-2" v-model="item.operator" :options="getOperators(item.field, item)" @change="onOperatorChange(item)"></b-form-select>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': item.operatorError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ $t('dataview.error.operator') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <FilterInput :disabled="disabled" v-model="query.children[itemIndex]" :schema="schema" :macros="macros"/>
            <div class="d-flex">
              <b-form-checkbox :disabled="disabled" v-if="allowEditing" class="allow-editing ml-1" v-model="item.allowEditing" @change="allowEditingChange(item)">
                {{$t('dataview.allow_edit')}}
              </b-form-checkbox>
              <b-btn :disabled="!item.allowEditing" class="edit-name-button" @click="onEditDisplayName(item)">
                <font-awesome-icon class="ml-1" :id="`EDIT_BUTTON_${id}_${itemIndex}`" :icon="['far', 'pen-to-square']"/>
                <b-popover
                  :target="`EDIT_BUTTON_${id}_${itemIndex}`"
                  placement="top"
                  triggers="hover"
                  :content="$t('dataview.edit_display_name')">
                </b-popover>
              </b-btn>
            </div>
          </div>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': item.valueError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ $t('dataview.error.value') }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': item.regexError }">
            <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ item.regexError }}
          </b-form-invalid-feedback>
        </div>
        <FilterComponent :disabled="disabled" v-if="item.type === 'group'" :level="level + 1" :root="root" :userId="userId" v-model="query.children[itemIndex]" :index="itemIndex" :schema="schema" :predicate="predicate" :macros="macros" @remove="onRemove"/>
      </div>
    </div>
    
    <FieldSelectModal :show.sync="showFieldSelect" :root="root" :userId="userId" :field="itemEdit !== null ? itemEdit.field : null" :agFunc="itemEdit !== null ? itemEdit.agFunc : null" :schema="schema" :macros="macros" :isFilter="true" @success="fieldSelectOk"/>
    
    <b-modal :title="$t('dataview.confirmation.allow_editing')"
        v-model="editDisplayName"
        size="md"
        no-close-on-backdrop  content-class="shadow" modal-class="anti-shift"
        @ok="editDisplayNameOk"
        >
      <label class="d-block mr-1">{{ $t(`dataview.chart.field_name`) }}</label>
      <b-form-input disabled class="d-block w-auto mt-1" v-model="editDisplayNameField" ></b-form-input>
      <b-form-group class="mt-2" :label="$t('dataview.chart.display_name')" label-for="displayname">
        <b-input-group>
          <b-form-input type="text" :readonly="disabled"
            v-model="editDisplayNameVal" >
          </b-form-input>
        </b-input-group>
      </b-form-group>
      <template v-slot:modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button v-if="!disabled" :disabled="editDisplayNameVal === null" size="sm" variant="success" @click="editDisplayNameOk">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { formatField } from '@/helpers';

const calendarProperties = [
  {
    "field": "color",
    "type": "String",
    "min": 7,
    "max": 7
  },
  {
    "field": "endDate",
    "type": "Date",
    "min": 0,
    "max": 32503680000000
  },
  {
    "field": "endHour",
    "type": "Time",
    "min": 0,
    "max": 86400000
  },
  {
    "field": "identifier",
    "type": "String",
    "min": 0,
    "max": 200,
    "index": "Composite"
  },
  {
    "field": "isWorking",
    "type": "Boolean",
    "options": [
      "true",
      "false"
    ]
  },
  {
    "field": "name",
    "type": "String",
    "notNull": true,
    "min": 0,
    "max": 200,
    "index": "Mixed"
  },
  {
    "field": "startDate",
    "type": "Date",
    "min": 0,
    "max": 32503680000000
  },
  {
    "field": "startHour",
    "type": "Time",
    "min": 0,
    "max": 86400000
  },
  {
    "field": "type",
    "type": "Enum",
    "options": [
      "Leave",
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Working"
    ],
    "notNull": true
  },
  {
    "field": "uuId",
    "type": "UUID",
    "readOnly": true,
    "index": "Composite"
  }
];

export default {
  name: 'FilterComponent',
  components: {
    FieldSelectModal:  () => import('@/components/modal/FieldSelectModal'),
    FilterInput: () => import('@/components/Filter/FilterInput')
  },
  props: {
    value: { type: Object, default: function() { return { type: "group", operator: "_and_", children: []} } },
    schema: { type: Object, default: null },
    customFields: { type: Object, default: null },
    predicate: { type: Object, default: null },
    level: { type: Number, default: 0 },
    index: { type: Number, default: 0 },
    userId:       { type: String, default: null },
    disabled: { type: Boolean, default: false },
    root: { type: String, default: null },
    macros: { type: Object, default: null },
    allowEditing: { type: Boolean, default: true }
  },
  data() {
    return {
      id: Math.random().toString(36).substr(2, 9),
      showFieldSelect: false,
      itemEdit: null,
      query: { type: "group", operator: "_and_", children: []},
      groupOptions: [{ text: 'AND', value: '_and_'},
                     { text: 'OR', value: '_or_'},
                     { text: 'NOT', value: '_not_'}],
      editDisplayName: false,
      editDisplayNameVal: null,
      editDisplayNameField: null,
      editItem: null
    }
  },
  watch: {
    query: {
      handler: function(newValue) {
        this.$emit('input', newValue);
      },
      deep: true
    },
    value(newValue) {
      this.query = newValue !== null ? newValue : { type: "group", operator: "_and_", children: []};
    }
  },
  mounted() {
    if (this.value !== null) {
      this.query = cloneDeep(this.value);
    }
  },
  computed: {
      
  },
  methods: {
    formatField(item) {
      return formatField(item);
    },
    onOperatorChange(item) {
      this.$set(item, 'operatorError', false);
    },
    editField(item) {
      this.$set(item, 'fieldError', false);
      this.itemEdit = item;
      this.showFieldSelect = true;
    },
    fieldSelectOk(field) {
      this.itemEdit.field = field.result;
      this.itemEdit.agFunc = field.agFunc;
    },
    getClass(level) {
      if (level === 0) {
        return "";
      }
      else if (level === 1) {
        return "level-1";
      }
      else if (level === 2) {
        return "level-2";
      }
    },
    getRuleClass(level) {
      if (level === 0) {
        return "level-1";
      }
      else if (level === 1) {
        return "level-2";
      }
      else if (level === 2) {
        return "level-3";
      }
    },
    addRule(group) {
      group.push({type: "rule", fieldtype: "", field: "", operator: "", value: ""});
    },
    addGroup(group) {
      group.push({ type: "group", operator: "_and_", children: []});
    },
    removeItem(index) {
      this.query.children.splice(index, 1);
    },
    removeGroup() {
      this.$emit('remove', this.index);
    },
    onRemove(index) {
      this.removeItem(index);
    },
    formatOperators(operators) {
      var retVal = [];
      
      if (typeof operators === 'undefined') {
        return retVal;
      }
      
      for (var operator of operators) {
        retVal.push({ value: operator, text: this.$t(`dataview.operator.${operator}`) });
      }
      retVal.sort(function(a, b) {
        return a.text.localeCompare(b.text);
      });
      return retVal;
    },
    editDisplayNameOk() {
      this.editItem.displayName = this.editDisplayNameVal;
      this.editDisplayName = false;
    },
    onEditDisplayName(item) {
      this.editItem = item;
      this.editDisplayNameField = this.formatField(item);
      this.editDisplayNameVal = item.displayName ? item.displayName : this.updateName(this.editDisplayNameField);
      this.editDisplayName = true;
    },
    allowEditingChange(item) {
      if (item.allowEditing) {
        this.onEditDisplayName(item);
      }
    },
    updateName(key) {
      var myRegexp = /(.+?)\((.+?)\)/g;
      var match = key.includes('.=') ? null : myRegexp.exec(key);
      const fieldlist = match === null ? key.split('.') : match[2].split('.');
      const entity = fieldlist.length > 1 ? fieldlist[fieldlist.length - 2] : fieldlist[0];
      const field = fieldlist[fieldlist.length - 1];
      const words = field.replace(/\s*\(.*?\)\s*/g, '').replace(/^=*/g, "").match(/[A-Z]*[^A-Z]+/g);
      const customField = this.customFields !== null ? this.customFields[`${entity}.${field}`] : null;
      const name = words !== null ? words.join(' ') : field;
      
      const retVal = customField ? customField.profile.displayName : `${entity[0] + entity.substr(1).toLowerCase()} ${name[0].toUpperCase()}${name.substr(1)}`;
      if (match === null) {
        return retVal;
      }
      else {
        return `${match[1]}(${retVal})`;
      }
    },
    getOperators(field, item) {
      const fieldlist = field.split('.');
      const index = fieldlist.length;
      if (index <= 1) {
        return [];
      }
      
      const fieldname = fieldlist[fieldlist.length - 1];
      if (fieldname.startsWith('=')) {
        const macro = this.macros[fieldname];
        var predicateType = macro.type;
        if (predicateType === 'Long') {
          predicateType = 'Integer';
        }
        else if (predicateType === 'Double') {
          predicateType = 'Float';
        }
        else if (predicateType === 'Array') {
          predicateType = 'String';
        }
        const operators = typeof this.predicate[predicateType] !== 'undefined' ? this.predicate[predicateType] : [];
        item.fieldtype = macro.type === 'Array' ? 'String' : macro.type;
        item.min = macro.min;
        item.max = macro.max;
        if ((item.fieldtype === 'Integer' || item.fieldtype === 'Float' || item.fieldtype === 'Double' || item.fieldtype === 'Long') &&
            typeof item.value === 'string') {
          item.value = 0;    
        }
        return this.formatOperators(operators);
      } 
      else if (fieldlist[index - 2] !== null) {
        var entity = fieldlist[index - 2];
        var schemaObj = this.schema[entity];
        if (typeof schemaObj === 'string') {
          entity = schemaObj;
          schemaObj = this.schema[entity];
        }
        
        const properties = entity === 'CALENDAR' ? calendarProperties : schemaObj.properties;
        
        if (properties !== null) {
          const prop = properties.filter(p =>  p.field === fieldname);
          if (prop.length !== 0) {
            item.fieldtype = item.agFunc && item.agFunc.applyFunction ? 'Float' : prop[0].type;
            if ((item.fieldtype === 'Integer' || item.fieldtype === 'Float' || item.fieldtype === 'Double' || item.fieldtype === 'Long') &&
                typeof item.value === 'string') {
              if (item.operator !== 'inside' &&
                  item.operator !== 'outside' &&
                  item.operator !== 'between') {
                item.value = 0;
              }
              else {
                item.value = '';
              }    
            }
            
            item.min = prop[0].min;
            item.max = prop[0].max;
            let predicateType = item.agFunc && item.agFunc.applyFunction ? item.fieldtype : prop[0].type;
            if (predicateType === 'Long' ||
                predicateType === 'Time') {
              predicateType = 'Integer';
            }
            else if (predicateType === 'Double') {
              predicateType = 'Float';
            }
            else if (predicateType === 'Enum') {
              return this.formatOperators(this.predicate[predicateType].filter(p => p !== 'lt' &&
                                                                                    p !== 'gt' &&
                                                                                    p !== 'lte' &&
                                                                                    p !== 'gte' &&
                                                                                    p !== 'inside' &&
                                                                                    p !== 'outside' &&
                                                                                    p !== 'between'));
            }
            else if (predicateType === 'String') {
              return this.formatOperators(this.predicate[predicateType].filter(p => p !== 'lt' &&
                                                                                    p !== 'gt' &&
                                                                                    p !== 'lte' &&
                                                                                    p !== 'gte'));
            }
            return this.formatOperators(this.predicate[predicateType]);
          }
        }
      }
      
      return [];
    }
  }
}
</script>

<style lang="scss">
.clickable {
  cursor: pointer;
}
.filter-group {
  border: 1px solid var(--form-control-border);
}

.filter-header, .filter-rule {
  position: relative;
  background-color: var(--ag-header-background-color);
  padding: 5px;
  border-bottom: 1px solid var(--form-control-border);
  margin-bottom: 5px;
}

.filter-header-alert {
  width: 50%;
  position: absolute !important;
  top: 3px;
  left: 165px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.filter-buttons {
  margin: 20px;
}

.btn-icon-color {
  color: var(--text-light) !important;
}

.edit-icon-color {
  color: var(--grid-toolbar-button) !important;
}

.btn-remove {
  float: right;
  top: 10px;
  position: relative;
  border: none;
  background-color: transparent;
}

.btn-rule-remove {
  float: right;
  position: relative;
  top: 35px;
  border: none;
  background-color: transparent;
}

.level-1 {
  border-left: 2px solid var(--filter-level1);
  margin: 20px;
  border-radius: 3px;
}

.level-2 {
  border-left: 2px solid var(--filter-level2);
  margin: 20px;
  border-radius: 3px;
}

.level-3 {
  border-left: 2px solid var(--filter-level3);
  margin: 20px;
  border-radius: 3px;
}

.edit-icon-container {
  vertical-align: bottom;
  height: 35px;
}

.item-field-label {
  color: var(--form-control);
  padding: 7px;
  margin-right: 5px;   
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-field-container {
  display: inline-block;
  margin: 1px;
  border: 1px solid var(--form-control-border);
  border-radius: 3px;
  max-height: 35px;
  background-color: var(--form-control-bg);
}

.radio-group-class {
  position: relative;
  top: 5px;
}

.allow-editing {
  margin-top: 7px;
}

.edit-help-icon {
  margin-top: 10px;
}

.edit-name-button.btn-secondary {
  background-color: transparent;
  border: none;
  color: var(--text-light);
  height: 35px;
}

.edit-name-button.btn-secondary.disabled  {
  background-color: transparent;
  border: none;
  color: var(--text-disabled);
  box-shadow: none;
}

.edit-name-button.btn-secondary:hover,
.edit-name-button.btn-secondary:focus  {
  background-color: transparent;
  border: none;
  color: var(--text-light);
  box-shadow: none;
}
</style>